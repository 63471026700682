import { createStore } from 'vuex';

import account from './account';
import company from './company';
import permission from './permission';
import vehicle from './vehicle';
import vehicleType from './vehicle-type';
import vehicleReservation from './vehicle-reservation';
import theme from './theme';
import equipment from './equipment';
import person from './person';

export const store = createStore({
  modules: {
    account,
    company,
    permission,
    vehicle,
    vehicleType,
    theme,
    equipment,
    person,
    vehicleReservation,
  },
});
