import { wsClient } from './ws-client';
import { authorizationService } from '../modules/authorization/authorization.service';

const getToken = async () => {
  let token = localStorage.getItem('accessToken');

  const [_, payload] = token.split('.');
  const payloadJson = atob(payload);
  const payloadData = JSON.parse(payloadJson);

  if (Date.now() >= payloadData.exp * 1000) {
    const accessToken = await authorizationService.refreshToken();
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      token = accessToken;
    } else {
      window.location.reload();
    }
  }

  return token;
};

export const makeRequest = async ({ query, variables, operationName }) => {
  return wsClient
    .iterate({ query, variables: { ...variables, token: await getToken() }, operationName })
    .next()
    .then(({ value }) => value);
};

export const subscribe = async ({ query, variables, operationName }) => {
  return wsClient.iterate({ query, variables: { ...variables, token: await getToken() }, operationName });
};
