export const vehicleFragment = `
  fragment VehicleFragment on Vehicle {
    id
    companyId
    createdAt
    createdBy
    createdByAccount {
      ...AccountFragment
    }
    updatedAt
    updatedBy
    updatedByAccount {
      ...AccountFragment
    }
    deletedAt
    deletedBy
    deletedByAccount {
      ...AccountFragment
    }

    unit
    typeId
    type {
      ...VehicleTypeFragment
    }
    vinNumber
    plateNumber
    onHoldTill
    isActive
    isInService
    hasTurboLogo
    hasMmmLogo
    registrationExpiresAt
    coiExpiresAt
    isTeam
    comments
    notes
    companyName
    length
    width
    height
    doorWidth
    doorHeight
    capacity
    locationId
    location {
      ...LocationFragment
    }
    onRouteTill
    contacts {
      ...VehicleContactFragment
    }
    equipments {
      ...VehicleEquipmentFragment
    }
    reservations {
      id
      vehicleId
      accountId
      account {
        login
      }
      expiresAt
      createdAt
      updatedAt
    }

    notesHistory {
      fromValue
      toValue
      createdAt
      createdBy
      createdByAccount {
        ...AccountFragment
      }
    }
  }
`;
