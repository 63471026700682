import { accountFragment, vehicleTypeFragment } from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToVehicleTypeCreating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleTypeCreated',
    query: `
      subscription VehicleTypeCreated {
        vehicleTypeCreated {
          ...VehicleTypeFragment
        }
      }

      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleTypeCreated;
    if (data) {
      store.dispatch('vehicleType/addToList', data);
    }
  }
};

export const subscribeToVehicleTypeUpdating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleTypeUpdated',
    query: `
      subscription VehicleTypeUpdated {
        vehicleTypeUpdated {
          ...VehicleTypeFragment
        }
      }

      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleTypeUpdated;
    if (data) {
      store.dispatch('vehicleType/updateInList', data);
    }
  }
};

export const subscribeToVehicleTypeDeleting = async () => {
  const stream = await subscribe({
    operationName: 'VehicleTypeDeleted',
    query: `
      subscription VehicleTypeDeleted {
        vehicleTypeDeleted {
          ...VehicleTypeFragment
        }
      }

      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleTypeDeleted;
    if (data) {
      store.dispatch('vehicleType/removeFromList', data.id);
    }
  }
};
