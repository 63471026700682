import { accountFragment, equipmentFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 * @param {boolean?} deletedOnly
 */
export const getEquipmentList = (take, skip, search, deletedOnly) =>
  makeRequest({
    operationName: 'EquipmentList',
    query: `
      ${equipmentFragment}
      ${accountFragment}

      query EquipmentList($take: Int, $skip: Int, $search: String, $deletedOnly: Boolean) {
        equipments(take: $take, skip: $skip, search: $search, deletedOnly: $deletedOnly) {
          ...EquipmentFragment
        }
      }
    `,
    variables: { take, skip, search, deletedOnly },
  });

/**
 * @param {string} id
 */
export const findEquipment = id =>
  makeRequest({
    operationName: 'Equipment',
    query: `
      query Equipment($id: String!) {
        equipment(id: $id) {
          ...EquipmentFragment
        }
      }
      
      ${equipmentFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
