import { accountFragment, equipmentFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {import('fifty-seven-llc-common').EquipmentStructure} data
 */
export const createEquipment = data =>
  makeRequest({
    operationName: 'CreateEquipment',
    query: `
      mutation CreateEquipment($data: EquipmentCreateInput!) {
        createEquipment(data: $data) {
          ...EquipmentFragment
        }
      }
      
      ${equipmentFragment}
      ${accountFragment}
    `,
    variables: { data },
  });

/**
 * @param {string} id
 * @param {Partial<import('fifty-seven-llc-common').EquipmentStructure>} data
 */
export const updateEquipment = (id, data) =>
  makeRequest({
    operationName: 'UpdateEquipment',
    query: `
      mutation UpdateEquipment($id: String!, $data: EquipmentUpdateInput!) {
        updateEquipment(id: $id, data: $data) {
          ...EquipmentFragment
        }
      }
      
      ${equipmentFragment}
      ${accountFragment}
    `,
    variables: { id, data },
  });

/**
 * @param {string} id
 */
export const deleteEquipment = id =>
  makeRequest({
    operationName: 'DeleteEquipment',
    query: `
      mutation DeleteEquipment($id: String!) {
        deleteEquipment(id: $id) {
          ...EquipmentFragment
        }
      }

      ${equipmentFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

/**
 * @param {string} id
 */
export const restoreEquipment = id =>
  makeRequest({
    operationName: 'RestoreEquipment',
    query: `
      mutation RestoreEquipment($id: String!) {
        restoreEquipment(id: $id) {
          ...EquipmentFragment
        }
      }
      
      ${equipmentFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
