import { createClient } from 'graphql-ws';
import { createStreamListener } from './stream';

if (!window.wsClient) {
  let firstConnected = false;
  window.wsClient = createClient({
    url: `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${window.location.host}/graphql.2`,
    keepAlive: 5000,
    retryAttempts: Infinity,
    // retryWait: count => count * 1000,
    // shouldRetry: () => true,
    on: {
      connected: () => {
        console.log('connected');

        if (!firstConnected) {
          createStreamListener();
          firstConnected = true;
        }

        document.querySelector('#disconnected')?.remove();
      },
      closed: () => {
        console.log('disconnected');

        if (!document.querySelector('#disconnected')) {
          const disconnected = document.createElement('div');
          disconnected.id = 'disconnected';
          disconnected.innerHTML = 'Disconnected from server';
          window.document.body.appendChild(disconnected);
        }
      },
      error: error => console.error(error),
    },
  });
}

export const wsClient = window.wsClient;
