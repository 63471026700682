import { accountFragment } from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToAccountCreating = async () => {
  const stream = await subscribe({
    operationName: 'AccountCreated',
    query: `
      subscription AccountCreated {
        accountCreated {
          ...AccountFragment
        }
      }

      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.accountCreated;
    if (data) {
      store.dispatch('account/addToList', data);
    }
  }
};

export const subscribeToAccountUpdating = async () => {
  const stream = await subscribe({
    operationName: 'AccountUpdated',
    query: `
      subscription AccountUpdated {
        accountUpdated {
          ...AccountFragment
        }
      }

      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.accountUpdated;
    if (data) {
      store.dispatch('account/updateInList', data);
    }
  }
};

export const subscribeToAccountDeleting = async () => {
  const stream = await subscribe({
    operationName: 'AccountDeleted',
    query: `
      subscription AccountDeleted {
        accountDeleted {
          ...AccountFragment
        }
      }
      
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.accountDeleted;
    if (data) {
      store.dispatch('account/removeFromList', data.id);
    }
  }
};
