import { accountFragment, locationFragment, personFragment } from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToPersonCreating = async () => {
  const stream = await subscribe({
    operationName: 'PersonCreated',
    query: `
      subscription PersonCreated {
        personCreated {
          ...PersonFragment
        }
      }

      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.personCreated;
    if (data) {
      store.dispatch('person/addToList', data);
    }
  }
};

export const subscribeToPersonUpdating = async () => {
  const stream = await subscribe({
    operationName: 'PersonUpdated',
    query: `
      subscription PersonUpdated {
        personUpdated {
          ...PersonFragment
        }
      }

      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.personUpdated;
    if (data) {
      store.dispatch('person/updateInList', data);
    }
  }
};

export const subscribeToPersonDeleting = async () => {
  const stream = await subscribe({
    operationName: 'PersonDeleted',
    query: `
      subscription PersonDeleted {
        personDeleted {
          ...PersonFragment
        }
      }

      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.personDeleted;
    if (data) {
      store.dispatch('person/removeFromList', data.id);
    }
  }
};
