import {
  accountFragment,
  equipmentFragment,
  locationFragment,
  personFragment,
  vehicleContactFragment,
  vehicleEquipmentFragment,
  vehicleFragment,
  vehicleReservationFragment,
  vehicleTypeFragment,
} from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToVehicleReservationCreating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleReservationCreated',
    query: `
      subscription VehicleReservationCreated {
        vehicleReservationCreated {
          ...VehicleReservationFragment
        }
      }

      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleReservationCreated;
    if (data) {
      store.dispatch('vehicleReservation/addToList', data);
    }
  }
};

export const subscribeToVehicleReservationUpdating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleReservationUpdated',
    query: `
      subscription VehicleReservationUpdated {
        vehicleReservationUpdated {
          ...VehicleReservationFragment
        }
      }

      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleReservationUpdated;
    if (data) {
      store.dispatch('vehicleReservation/updateInList', data);
    }
  }
};

export const subscribeToVehicleReservationDeleting = async () => {
  const stream = await subscribe({
    operationName: 'VehicleReservationDeleted',
    query: `
      subscription VehicleReservationDeleted {
        vehicleReservationDeleted {
          ...VehicleReservationFragment
        }
      }

      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleReservationDeleted;
    if (data) {
      store.dispatch('vehicleReservation/removeFromList', data);
    }
  }
};
