export function sleep(duration) {
  return new Promise(r => setTimeout(r, duration));
}

export function throttle(fn, delay) {
  let timeoutId;
  return function(...args) {
    if (timeoutId) {
      return;
    }
    timeoutId = setTimeout(() => {
      fn(...args);
      timeoutId = null;
    }, delay);
  };
}

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const dateFormatterConfig = {
  timeZone: 'America/New_York',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

const dateTimeFormatterConfig = {
  ...dateFormatterConfig,
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const dateTimeWithSecondsFormatterConfig = {
  ...dateTimeFormatterConfig,
  second: '2-digit',
};

export const dateFormatter = Intl.DateTimeFormat('en-US', dateFormatterConfig);
export const dateTimeFormatter = Intl.DateTimeFormat('en-US', dateTimeFormatterConfig);
export const dateTimeWithSecondsFormatter = Intl.DateTimeFormat('en-US', dateTimeWithSecondsFormatterConfig);

export const getIsoTimezone = date => {
  const formattedDateTime = Intl.DateTimeFormat('en-US', { timeZone: dateFormatterConfig.timeZone, timeZoneName: 'shortOffset' }).format(
    new Date(date),
  );

  const [_, timezoneName] = formattedDateTime.split(' ');
  const timezone = timezoneName.replace('GMT', '');
  const isNegativeTimezone = timezone.startsWith('-');
  const timezoneHours = timezone.substring(1);

  return `${isNegativeTimezone ? '-' : '+'}${timezoneHours.padStart(2, '0')}:00`;
};

export const dateToInputDate = date =>
  Intl.DateTimeFormat('sv-SE', dateFormatterConfig)
    .format(new Date(date))
    .replace(' ', 'T');
export const dateToInputDateTime = date =>
  Intl.DateTimeFormat('sv-SE', { ...dateTimeFormatterConfig, hour12: false })
    .format(new Date(date))
    .replace(' ', 'T');

export const inputDateToDate = inputDate => new Date(`${inputDate}T00:00:00.000${getIsoTimezone(inputDate)}`);
export const inputDateTimeToDate = inputDateTime => new Date(`${inputDateTime}:00.000${getIsoTimezone(inputDateTime)}`);

export const isVehicleRegistrationExpiresAtLowerThan = (vehicle, date) => new Date(vehicle.registrationExpiresAt) < date;
export const isVehicleCoiExpiresAtLowerThan = (vehicle, date) => new Date(vehicle.coiExpiresAt) < date;

export const isVehicleActiveByRules = vehicle => {
  const { registrationExpiresAt, coiExpiresAt, onHoldTill } = vehicle;
  const now = new Date();

  // if (!registrationExpiresAt || isVehicleRegistrationExpiresAtLowerThan(vehicle, now)) {
  //   return false;
  // }

  if (!coiExpiresAt || isVehicleCoiExpiresAtLowerThan(vehicle, now)) {
    return false;
  }

  if (onHoldTill && new Date(onHoldTill) > now) {
    return false;
  }

  return true;
};

export const hasFlag = (number, flag) => !!(number & flag);

export const addFlag = (number, flag) => number | flag;

export const removeFlag = (number, flag) => number & ~flag;
