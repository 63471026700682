import { accountFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 */
export const getAccountList = (take = 30, skip = 0, search) =>
  makeRequest({
    operationName: 'AccountList',
    query: `
      query AccountList($take: Int, $skip: Int, $search: String) {
        accounts(take: $take, skip: $skip, search: $search) {
          ...AccountFragment
        }
      }
      
      ${accountFragment}
    `,
    variables: { take, skip, search },
  });

/**
 * @param {string} id
 */
export const findAccount = id =>
  makeRequest({
    operationName: 'Account',
    query: `
      query Account($id: String!) {
        account(id: $id) {
          ...AccountFragment
        }
      }
      
      ${accountFragment}
    `,
    variables: { id },
  });

export const me = () =>
  makeRequest({
    operationName: 'Me',
    query: `
      query Me {
        me {
          ...AccountFragment
        }
      }
      
      ${accountFragment}
    `,
  });
