const ADD_TO_LIST = (state, value) => {
  state.list[value.id] = value;
};

const UPDATE_IN_LIST = (state, value) => {
  state.list[value.id] = value;
};

const REMOVE_FROM_LIST = (state, value) => {
  delete state.list[value];
};

const CLEAR_LIST = (state, value) => {
  state.list = {};
};

export default {
  ADD_TO_LIST,
  UPDATE_IN_LIST,
  REMOVE_FROM_LIST,
  CLEAR_LIST,
};
