import { accountFragment, companyFragment } from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToCompanyCreating = async () => {
  const stream = await subscribe({
    operationName: 'CompanyCreated',
    query: `
      subscription CompanyCreated {
        companyCreated {
          ...CompanyFragment
        }
      }

      ${companyFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.companyCreated;
    if (data) {
      store.dispatch('company/addToList', data);
    }
  }
};

export const subscribeToCompanyUpdating = async () => {
  const stream = await subscribe({
    operationName: 'CompanyUpdated',
    query: `
      subscription CompanyUpdated {
        companyUpdated {
          ...CompanyFragment
        }
      }

      ${companyFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.companyUpdated;
    if (data) {
      store.dispatch('company/updateInList', data);
    }
  }
};

export const subscribeToCompanyDeleting = async () => {
  const stream = await subscribe({
    operationName: 'CompanyDeleted',
    query: `
      subscription CompanyDeleted {
        companyDeleted {
          ...CompanyFragment
        }
      }
      
      ${companyFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.companyDeleted;
    if (data) {
      store.dispatch('company/removeFromList', data.id);
    }
  }
};
