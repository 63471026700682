const addToList = ({ commit }, payload) => {
  commit('ADD_TO_LIST', payload);
};

const updateInList = ({ commit }, payload) => {
  commit('UPDATE_IN_LIST', payload);
};

const removeFromList = ({ commit }, payload) => {
  commit('REMOVE_FROM_LIST', payload);
};

const clearList = ({ commit }, payload) => {
  commit('CLEAR_LIST', payload);
};

export default {
  addToList,
  updateInList,
  removeFromList,
  clearList,
};
