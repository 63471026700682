<template>
  <SidebarMenu
    :menu="menu"
    :collapsed="true"
    :disableHover="true"
    :hideToggle="true"
    widthCollapsed="3.6em"
    width="14em"
    @item-click="onItemClick"
  ></SidebarMenu>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import { authorizationService } from '@/modules/authorization/authorization.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Sidebar',
  components: { SidebarMenu },
  computed: {
    ...mapGetters(['account/current']),

    menu() {
      const menu = [
        {
          href: '/',
          title: 'Map',
          icon: 'fa fa-map',
        },
        {
          href: '/vehicles',
          title: 'Vehicles',
          icon: 'fa fa-car',
        },
        {
          href: '/persons',
          title: 'Persons',
          icon: 'fa fa-user-friends',
        },
      ];

      menu.push({
        title: this['account/current']?.login,
        icon: {
          element: 'span',
          class: 'account',
          attributes: { title: this['account/current']?.login },
          text: this['account/current']?.login.substring(0, 2),
        },
      });

      menu.push({
        title: 'Settings',
        href: '/settings',
        icon: 'fa fa-cog',
      });

      menu.push({
        title: 'Sing out',
        icon: 'fa fa-sign-out-alt',
        async click() {
          await authorizationService.signOut();
          localStorage.removeItem('accessToken');
          await this.$router.push({ name: 'login' });
        },
      });

      return menu;
    },
  },
  methods: {
    onItemClick(e, item) {
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'function') item[key].call(this);
      });
    },
  },
};
</script>

<style>
.v-sidebar-menu {
  background-color: var(--sidebar-background-color);
  box-shadow: 0.1rem 0 0.3rem 0 rgba(0, 0, 0, 0.25);
}

.v-sidebar-menu .vsm--list {
  position: relative;
  height: 100%;
}

.v-sidebar-menu .vsm--item:nth-last-child(3) {
  position: absolute !important;
  bottom: 6.4em;
}

.v-sidebar-menu .vsm--item:nth-last-child(2) {
  position: absolute !important;
  bottom: 3.2em;
}

.v-sidebar-menu .vsm--item:last-child {
  position: absolute !important;
  bottom: 0;
}

.v-sidebar-menu.vsm_collapsed {
  width: 3.2em;
}

.v-sidebar-menu .vsm--toggle-btn {
  height: 3.2em;
}

.v-sidebar-menu .vsm--link {
  font-size: 1em;
  padding: 0.4em;
}

.v-sidebar-menu .vsm--link > .vsm--icon {
  width: 2.4em;
  height: 2.4em;
  line-height: 2.4em;
  border-radius: 0.5em;
  transition: all 0.3s;

  background-color: var(--sidebar-item-color);
}

.v-sidebar-menu .vsm--link:hover .vsm--icon {
  background: var(--sidebar-item-active-color) !important;
}

.v-sidebar-menu .vsm--mobile-item {
  height: 3.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0.25em 0.25em 0;
  overflow: hidden;
}

.v-sidebar-menu .vsm--mobile-bg {
  background: #0d6efd;
}

.v-sidebar-menu .vsm--link.vsm--link_exact-active {
  box-shadow: 0.2em 0 0 0 var(--sidebar-item-active-color) inset;
}
</style>
