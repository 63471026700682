import {
  accountFragment,
  equipmentFragment,
  locationFragment,
  personFragment,
  vehicleContactFragment,
  vehicleFragment,
  vehicleReservationFragment,
  vehicleTypeFragment,
} from '../fragments';
import { vehicleEquipmentFragment } from '../fragments/vehicle-equipment';
import { makeRequest } from '../utils';

export const getMyReservationList = () =>
  makeRequest({
    operationName: 'MyReservations',
    query: `
      query MyReservations {
        myReservations {
          ...VehicleReservationFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
  });
