export const companyFragment = `
  fragment CompanyFragment on Company {
    id
    createdAt
    createdBy
    createdByAccount {
      ...AccountFragment
    }
    updatedAt
    updatedBy
    updatedByAccount {
      ...AccountFragment
    }
    deletedAt
    deletedBy
    deletedByAccount {
      ...AccountFragment
    }

    name
  }
`;
