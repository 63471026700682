import { permissionFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {string?} search
 */
export const getPermissionList = search =>
  makeRequest({
    operationName: 'PermissionList',
    query: `
      query PermissionList($search: String) {
        permissions(search: $search) {
          ...PermissionFragment
        }
      }
      
      ${permissionFragment}
    `,
    variables: { search },
  });
