export const accountFragment = `
  fragment AccountFragment on Account {
    id
    companyId
    login
    permissions
    createdAt
    updatedAt
    lastActiveAt
  }
`;
