import { accountFragment, companyFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 * @param {boolean?} deletedOnly
 */
export const getCompanyList = (take, skip, search, deletedOnly) =>
  makeRequest({
    operationName: 'CompanyList',
    query: `
      query CompanyList($take: Int, $skip: Int, $search: String, $deletedOnly: Boolean) {
        companies(take: $take, skip: $skip, search: $search, deletedOnly: $deletedOnly) {
          ...CompanyFragment
        }
      }
      
      ${companyFragment}
      ${accountFragment}
    `,
    variables: { take, skip, search, deletedOnly },
  });

/**
 * @param {string} id
 */
export const findCompany = id =>
  makeRequest({
    operationName: 'Company',
    query: `
      query Company($id: String!) {
        company(id: $id) {
          ...CompanyFragment
        }
      }
      
      ${companyFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
