export const personFragment = `
  fragment PersonFragment on Person {
    id
    companyId
    createdAt
    createdBy
    createdByAccount {
      ...AccountFragment
    }
    updatedAt
    updatedBy
    updatedByAccount {
      ...AccountFragment
    }
    deletedAt
    deletedBy
    deletedByAccount {
      ...AccountFragment
    }

    name
    phone
    email
    status
    canCrossBorder
    canCrossCanadianBorder
    canCrossMexicanBorder
    hasTsaCard
    hasTwicCard
    hasHazmatCert
    homeLocationId
    homeLocation {
      ...LocationFragment
    }
    hiredAt
    hiredBy
    hiredByAccount {
      ...AccountFragment
    }
    companyName

    langs
  }
`;
