export const vehicleContactFragment = `
  fragment VehicleContactFragment on VehicleContact {
    personId
    person {
      ...PersonFragment
    }
    isMain
    roles
  }
`;
