import { accountFragment, vehicleTypeFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 * @param {boolean?} deletedOnly
 */
export const getVehicleTypeList = (take, skip, search, deletedOnly) =>
  makeRequest({
    operationName: 'VehicleTypeList',
    query: `
      query VehicleTypeList($take: Int, $skip: Int, $search: String, $deletedOnly: Boolean) {
        vehicleTypes(take: $take, skip: $skip, search: $search, deletedOnly: $deletedOnly) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { take, skip, search, deletedOnly },
  });

/**
 * @param {string} id
 */
export const findVehicleType = id =>
  makeRequest({
    operationName: 'VehicleType',
    query: `
      query VehicleType($id: String!) {
        vehicleType(id: $id) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
