import { accountFragment, companyFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {import('fifty-seven-llc-common').CompanyStructure} data
 */
export const createCompany = data =>
  makeRequest({
    operationName: 'CreateCompany',
    query: `
      ${companyFragment}
      ${accountFragment}

      mutation CreateCompany($data: CompanyCreateInput!) {
        createCompany(data: $data) {
          ...CompanyFragment
        }
      }
    `,
    variables: { data },
  });

/**
 * @param {string} id
 * @param {Partial<import('fifty-seven-llc-common').CompanyStructure>} data
 */
export const updateCompany = (id, data) =>
  makeRequest({
    operationName: 'UpdateCompany',
    query: `
      ${companyFragment}
      ${accountFragment}

      mutation UpdateCompany($id: String!, $data: CompanyUpdateInput!) {
        updateCompany(id: $id, data: $data) {
          ...CompanyFragment
        }
      }
    `,
    variables: { id, data },
  });

/**
 * @param {string} id
 */
export const deleteCompany = id =>
  makeRequest({
    operationName: 'DeleteCompany',
    query: `
      ${companyFragment}
      ${accountFragment}

      mutation DeleteCompany($id: String!) {
        deleteCompany(id: $id) {
          ...CompanyFragment
        }
      }
    `,
    variables: { id },
  });

/**
 * @param {string} id
 */
export const restoreCompany = id =>
  makeRequest({
    operationName: 'RestoreCompany',
    query: `
      mutation RestoreCompany($id: String!) {
        restoreCompany(id: $id) {
          ...CompanyFragment
        }
      }

      ${companyFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
