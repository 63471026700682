export const locationFragment = `
  fragment LocationFragment on Location {
    id
    createdAt
    createdBy
    updatedAt
    updatedBy

    placeId
    formattedAddress

    queries

    postalCode
    shortNeighborhood
    longNeighborhood
    shortAdministrativeAreaLevel1
    longAdministrativeAreaLevel1
    shortAdministrativeAreaLevel2
    longAdministrativeAreaLevel2
    shortCountry
    longCountry

    lat
    lng
  }
`;
