export const vehicleReservationFragment = `
  fragment VehicleReservationFragment on VehicleReservation {
    id
    vehicleId
    accountId
    account {
      login
    }
    vehicle {
      ...VehicleFragment
    }
    expiresAt
    createdAt
    updatedAt
  }
`;
