import {
  accountFragment,
  equipmentFragment,
  locationFragment,
  personFragment,
  vehicleContactFragment,
  vehicleFragment,
  vehicleTypeFragment,
  vehicleEquipmentFragment,
} from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToVehicleCreating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleCreated',
    query: `
      subscription VehicleCreated {
        vehicleCreated {
          ...VehicleFragment
        }
      }

      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleCreated;
    if (data) {
      store.dispatch('vehicle/addToList', data);
    }
  }
};

export const subscribeToVehicleUpdating = async () => {
  const stream = await subscribe({
    operationName: 'VehicleUpdated',
    query: `
      subscription VehicleUpdated {
        vehicleUpdated {
          ...VehicleFragment
        }
      }

      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleUpdated;
    if (data) {
      store.dispatch('vehicle/updateInList', data);
    }
  }
};

export const subscribeToVehicleDeleting = async () => {
  const stream = await subscribe({
    operationName: 'VehicleDeleted',
    query: `
      subscription VehicleDeleted {
        vehicleDeleted {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleDeleted;
    if (data) {
      store.dispatch('vehicle/removeFromList', data.id);
    }
  }
};

export const subscribeToVehicleCounters = async () => {
  const stream = await subscribe({
    operationName: 'VehicleCounters',
    query: `
      subscription VehicleCounters {
        vehicleCounters {
          all
          active
          inactive
          inService
          notInService
          deleted
        }
      }
    `,
  });

  for await (const event of stream) {
    const data = event.data?.vehicleCounters;
    if (data) {
      store.dispatch('vehicle/setCounters', data);
    }
  }
};
