import {
  subscribeToCompanyCreating,
  subscribeToCompanyDeleting,
  subscribeToCompanyUpdating,
  subscribeToAccountCreating,
  subscribeToAccountDeleting,
  subscribeToAccountUpdating,
  subscribeToEquipmentCreating,
  subscribeToEquipmentDeleting,
  subscribeToEquipmentUpdating,
  subscribeToPersonCreating,
  subscribeToPersonDeleting,
  subscribeToPersonUpdating,
  subscribeToVehicleCreating,
  subscribeToVehicleDeleting,
  subscribeToVehicleReservationCreating,
  subscribeToVehicleReservationDeleting,
  subscribeToVehicleReservationUpdating,
  subscribeToVehicleTypeCreating,
  subscribeToVehicleTypeDeleting,
  subscribeToVehicleTypeUpdating,
  subscribeToVehicleUpdating,
  subscribeToVehicleCounters,
} from './subscriptions';

export const createStreamListener = () => {
  subscribeToCompanyCreating();
  subscribeToCompanyUpdating();
  subscribeToCompanyDeleting();

  subscribeToAccountCreating();
  subscribeToAccountUpdating();
  subscribeToAccountDeleting();

  subscribeToPersonCreating();
  subscribeToPersonUpdating();
  subscribeToPersonDeleting();

  subscribeToEquipmentCreating();
  subscribeToEquipmentUpdating();
  subscribeToEquipmentDeleting();

  subscribeToVehicleTypeCreating();
  subscribeToVehicleTypeUpdating();
  subscribeToVehicleTypeDeleting();

  subscribeToVehicleCreating();
  subscribeToVehicleUpdating();
  subscribeToVehicleDeleting();
  subscribeToVehicleCounters();

  subscribeToVehicleReservationCreating();
  subscribeToVehicleReservationUpdating();
  subscribeToVehicleReservationDeleting();
};
