import {
  accountFragment,
  equipmentFragment,
  locationFragment,
  personFragment,
  vehicleContactFragment,
  vehicleEquipmentFragment,
  vehicleFragment,
  vehicleTypeFragment,
} from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 * @param {boolean?} deletedOnly
 */
export const getVehicleList = async (take, skip, search, orderBy, deletedOnly, filter, fieldsToSearch) =>
  makeRequest({
    operationName: 'VehicleList',
    query: `
      query VehicleList(
        $take: Int
        $skip: Int
        $search: String
        $orderBy: [OrderBy!]
        $deletedOnly: Boolean
        $filter: VehicleListFilterInput
        $fieldsToSearch: [String!]
      ) {
        vehicles(
          take: $take
          skip: $skip
          search: $search
          orderBy: $orderBy
          deletedOnly: $deletedOnly
          filter: $filter
          fieldsToSearch: $fieldsToSearch
        ) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { take, skip, search, orderBy, deletedOnly, filter, fieldsToSearch },
  });

/**
 * @param {string} id
 */
export const findVehicle = id =>
  makeRequest({
    operationName: 'Vehicle',
    query: `
      query Vehicle($id: String!) {
        vehicle(id: $id) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

export const getVehicleCounters = () =>
  makeRequest({
    operationName: 'VehicleCounters',
    query: `
      query VehicleCounters {
        vehicleCounters {
          all
          active
          inactive
          inService
          notInService
          deleted
        }
      }
    `,
  });

/**
 * @param {any} point
 * @param {number} radius
 * @param {VehicleListFilterInput} filter
 */
export const getVehiclesInRadius = (point, radius, filter) =>
  makeRequest({
    operationName: 'VehicleListInRadius',
    query: `
      query VehicleListInRadius($point: String!, $radius: Float!, $filter: VehicleListFilterInput) {
        vehiclesInRadius(point: $point, radius: $radius, filter: $filter) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { point, radius, filter },
  });

/**
 * @param {any} point
 * @param {number} radius
 * @param {VehicleListFilterInput} filter
 */
export const getVehiclesInRadiusWithDistance = (point, radius, filter) =>
  makeRequest({
    operationName: 'VehicleListInRadiusWithDistance',
    query: `
      query VehicleListInRadiusWithDistance($point: String!, $radius: Float!, $filter: VehicleListFilterInput) {
        vehiclesInRadiusWithDistance(point: $point, radius: $radius, filter: $filter) {
          vehicle {
            ...VehicleFragment
          }
          distance
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { point, radius, filter },
  });

export const getVehiclesWithExpirations = () =>
  makeRequest({
    operationName: 'VehicleListWithExpirations',
    query: `
      query VehicleListWithExpirations {
        vehiclesWithExpirations {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
  });
