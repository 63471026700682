export const vehicleTypeFragment = `
  fragment VehicleTypeFragment on VehicleType {
    id
    companyId
    createdAt
    createdBy
    createdByAccount {
      ...AccountFragment
    }
    updatedAt
    updatedBy
    updatedByAccount {
      ...AccountFragment
    }
    deletedAt
    deletedBy
    deletedByAccount {
      ...AccountFragment
    }

    name
    color
  }
`;
