import { accountFragment, equipmentFragment } from '../fragments';
import { store } from '../../store';
import { subscribe } from '../utils';

export const subscribeToEquipmentCreating = async () => {
  const stream = await subscribe({
    operationName: 'EquipmentCreated',
    query: `
      subscription EquipmentCreated {
        equipmentCreated {
          ...EquipmentFragment
        }
      }

      ${equipmentFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.equipmentCreated;
    if (data) {
      store.dispatch('equipment/addToList', data);
    }
  }
};

export const subscribeToEquipmentUpdating = async () => {
  const stream = await subscribe({
    operationName: 'EquipmentUpdated',
    query: `
      subscription EquipmentUpdated {
        equipmentUpdated {
          ...EquipmentFragment
        }
      }

      ${equipmentFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.equipmentUpdated;
    if (data) {
      store.dispatch('equipment/updateInList', data);
    }
  }
};

export const subscribeToEquipmentDeleting = async () => {
  const stream = await subscribe({
    operationName: 'EquipmentDeleted',
    query: `
      subscription EquipmentDeleted {
        equipmentDeleted {
          ...EquipmentFragment
        }
      }

      ${equipmentFragment}
      ${accountFragment}
    `,
  });

  for await (const event of stream) {
    const data = event.data?.equipmentDeleted;
    if (data) {
      store.dispatch('equipment/removeFromList', data.id);
    }
  }
};
