import { createRouter, createWebHistory } from 'vue-router';
import { sleep } from '@/utils';
import { store } from '@/store';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('@/views/MapPage'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginPage'),
    },
    {
      path: '/vehicles',
      name: 'vehicles',
      component: () => import('@/views/vehicle/VehiclesPage'),
      meta: {
        permissions: ['vehicles.view', 'vehicles.view.own'],
      },
    },
    {
      path: '/vehicle/create',
      name: 'vehicle-create',
      component: () => import('@/views/vehicle/VehicleCreatePage'),
      meta: {
        permissions: ['vehicles.create'],
      },
    },
    {
      path: '/vehicle/edit/:id',
      name: 'vehicle-edit',
      component: () => import('@/views/vehicle/VehicleEditPage'),
      meta: {
        permissions: ['vehicles.edit', 'vehicles.view.own'],
      },
    },

    {
      path: '/persons',
      name: 'persons',
      component: () => import('@/views/person/PersonsPage'),
    },
    {
      path: '/person/create',
      name: 'person-create',
      component: () => import('@/views/person/PersonCreatePage'),
      meta: {
        permissions: ['persons.create'],
      },
    },
    {
      path: '/person/edit/:id',
      name: 'person-edit',
      component: () => import('@/views/person/PersonEditPage'),
      meta: {
        permissions: ['persons.edit'],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/SettingsPage'),
      children: [
        {
          path: 'companies',
          name: 'companies',
          component: () => import('@/views/company/CompaniesPage'),
          meta: {
            permissions: ['admin.companies.view'],
          },
        },
        {
          path: 'company/create',
          name: 'company-create',
          component: () => import('@/views/company/CompanyCreatePage'),
          meta: {
            permissions: ['admin.companies.create'],
          },
        },
        {
          path: 'company/edit/:id',
          name: 'company-edit',
          component: () => import('@/views/company/CompanyEditPage'),
          meta: {
            permissions: ['admin.companies.edit'],
          },
        },
        {
          path: 'accounts',
          name: 'accounts',
          component: () => import('@/views/account/AccountsPage'),
          meta: {
            permissions: ['accounts.view'],
          },
        },
        {
          path: 'account/create',
          name: 'account-create',
          component: () => import('@/views/account/AccountCreatePage'),
          meta: {
            permissions: ['accounts.create'],
          },
        },
        {
          path: 'account/edit/:id',
          name: 'account-edit',
          component: () => import('@/views/account/AccountEditPage'),
          meta: {
            permissions: ['accounts.edit'],
          },
        },
        {
          path: 'vehicle-types',
          name: 'vehicle-types',
          component: () => import('@/views/vehicle-type/VehicleTypesPage'),
        },
        {
          path: 'vehicle-type/create',
          name: 'vehicle-type-create',
          component: () => import('@/views/vehicle-type/VehicleTypeCreatePage'),
          meta: {
            permissions: ['vehicle-types.create'],
          },
        },
        {
          path: 'vehicle-type/edit/:id',
          name: 'vehicle-type-edit',
          component: () => import('@/views/vehicle-type/VehicleTypeEditPage'),
          meta: {
            permissions: ['vehicle-types.edit'],
          },
        },

        {
          path: 'equipments',
          name: 'equipments',
          component: () => import('@/views/equipment/EquipmentsPage'),
        },
        {
          path: 'equipment/create',
          name: 'equipment-create',
          component: () => import('@/views/equipment/EquipmentCreatePage'),
          meta: {
            permissions: ['equipments.create'],
          },
        },
        {
          path: 'equipment/edit/:id',
          name: 'equipment-edit',
          component: () => import('@/views/equipment/EquipmentEditPage'),
          meta: {
            permissions: ['equipments.edit'],
          },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login') return next();

  while (!store.getters['account/current']) await sleep(100);

  if (Array.isArray(to.meta.permissions)) {
    const account = store.getters['account/current'];
    if (!to.meta.permissions.some(p => account.hasPermission(p))) return next(from.name ? from : { name: 'main' });
  }

  next();
});
