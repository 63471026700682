import { accountFragment, locationFragment, personFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 * @param {boolean?} deletedOnly
 */
export const getPersonList = (take, skip, search, deletedOnly) =>
  makeRequest({
    operationName: 'PersonList',
    query: `
      query PersonList($take: Int, $skip: Int, $search: String, $deletedOnly: Boolean) {
        persons(take: $take, skip: $skip, search: $search, deletedOnly: $deletedOnly) {
          ...PersonFragment
        }
      }
      
      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { take, skip, search, deletedOnly },
  });

/**
 * @param {string} id
 */
export const findPerson = id =>
  makeRequest({
    operationName: 'Person',
    query: `
      query Person($id: String!) {
        person(id: $id) {
          ...PersonFragment
        }
      }

      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
