import { createApp } from 'vue';
import toast from '@k90mirzaei/vue-toast';
import * as Sentry from '@sentry/vue';
import '@k90mirzaei/vue-toast/dist/index.css';

import 'ag-grid-community/styles/ag-grid.min.css';
import 'ag-grid-community/styles/ag-theme-material.min.css';
import 'ag-grid-enterprise';

import App from '@/App.vue';
import { router } from '@/router';
import { store } from '@/store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

async function bootstrap() {
  if ('serviceWorker' in navigator) {
    await navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.unregister();
      }
    });

    // await navigator.serviceWorker
    //   .register('/sw.js')
    //   .then(reg => {
    //     console.log('Registration succeeded. Scope is ' + reg.scope);
    //   })
    //   .catch(error => {
    //     console.log('Registration failed with ' + error);
    //   });
  }

  const app = createApp(App);

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing({ routingInstrumentation: Sentry.vueRouterInstrumentation(router) }), new Sentry.Replay()],
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
  });

  app.use(store);
  app.use(router);
  app.use(toast);

  app.mount('#app');

  window.app = app;
}
bootstrap();
