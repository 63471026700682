<template>
  <div class="app">
    <Sidebar v-if="sidebarVisible"></Sidebar>

    <router-view v-slot="{ Component }">
      <keep-alive include="MapPage">
        <component :is="Component" />
      </keep-alive>
    </router-view>

    <i class="theme-toggle fa fa-adjust" @click="toggleTheme"></i>
  </div>
</template>

<script>
import { AccountEntity } from 'fifty-seven-llc-common';
import { api } from '@/modules/common/api';
import { authorizationService } from '@/modules/authorization/authorization.service';
import { me } from '@/api';
import Sidebar from '@/components/Sidebar';
import { mapGetters } from 'vuex';

export default {
  components: { Sidebar },
  computed: {
    ...mapGetters(['theme/darkMode', 'theme/name']),
    sidebarVisible() {
      return this.$route.name !== 'login';
    },
    darkMode() {
      return this['theme/darkMode'];
    },
    themeName() {
      return this['theme/name'];
    },
  },
  data: () => ({
    authorized: false,
    pingInterval: null,
  }),
  methods: {
    async authorize() {
      let accessToken = localStorage.getItem('accessToken');
      let success = await authorizationService.authorize(accessToken);
      if (!success) {
        accessToken = await authorizationService.refreshToken();
        if (accessToken) {
          localStorage.setItem('accessToken', accessToken);
          success = await authorizationService.authorize(accessToken);
        }
      }

      const accessTokenData = accessToken?.split('.')?.[1];
      if (accessTokenData) {
        const accessTokenDataJson = JSON.parse(atob(accessTokenData));
        const { exp } = accessTokenDataJson;
        const now = Math.floor(Date.now() / 1000);
        const diff = exp - now;
        setTimeout(() => {
          this.authorize();
        }, diff * 1000);
      }

      window.addEventListener('storage', this.storageChanged);

      if (success) {
        api.canSendData = success;

        me()
          .then(response => {
            const account = response.data.me;

            const accountEntity = new AccountEntity(
              account.id,
              account.login,
              account.hash,
              account.companyId,
              new Set(account.permissions),
              account.createdAt,
              account.updatedAt,
              account.lastActiveAt,
            );

            accountEntity.hasPermission = function hasPermission(permission) {
              let regexp;
              for (let p of [...this.permissions.values()].sort((a, b) => b.length - a.length)) {
                regexp = RegExp(
                  `^${p
                    .replace(/[.+?^${}()|[\]\\]/g, '\\$&')
                    .replace('*', '.+')
                    .replace(/^-/, '')}$`,
                );
                if (regexp.test(permission) || regexp.test('admin.' + permission)) {
                  return !p.startsWith('-');
                }
              }

              return false;
            };

            this.$store.dispatch('account/setCurrent', accountEntity);
          })
          .catch(error => {
            console.log(error);
            this.$toast.error(error.message);
          });

        await import('@/api/ws-client');

        if (this.$route.name === 'login') await this.$router.push({ name: 'main' });

        this.pingInterval = setInterval(() => api.ping(), 1000 * 30);
      } else {
        if (this.$route.name !== 'login') await this.$router.push({ name: 'login' });
      }
    },

    storageChanged(e) {
      if (e.key === 'accessToken' || e.key === null) {
        window.removeEventListener('storage', this.storageChanged);
        api.reconnect();
      }
    },

    toggleTheme() {
      this.$store.dispatch('theme/toggleDarkMode');
    },

    setTheme(value) {
      document.body.dataset.theme = value;
    },
  },
  created() {
    if (JSON.parse(localStorage.getItem('darkMode')) === true) this.$store.dispatch('theme/toggleDarkMode');

    api.on('connected', () => this.authorize());
    api.on('disconnected', () => clearInterval(this.pingInterval));
    api.connect();

    this.$store.watch(
      (state, getters) => getters['account/current'],
      async (newValue, oldValue) => {
        if (!newValue && !!oldValue) {
          await authorizationService.signOut();
          localStorage.removeItem('accessToken');
          await this.$router.push({ name: 'login' });
        }
      },
    );

    this.$store.watch((state, getters) => getters['theme/name'], this.setTheme);
    this.setTheme(this.themeName);

    document.addEventListener('mousedown', e => {
      if (e.button !== 0) {
        return;
      }

      const closestModal = e.target.closest('.modal-content');

      document.querySelectorAll('.modal.show .modal-content').forEach(modal => {
        if (modal !== closestModal) {
          modal.querySelector('.btn-close')?.click();
        }
      });
    });
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@600&display=swap');

@font-face {
  font-family: 'Refinery 95 Bold';
  src: url('/fonts/refinery-95-bold.ttf');
}

:root {
  --color-natural-white: #fefefe;
  --color-natural-grey-5: #f5f6f8;

  --color-shadow-context-menu: rgba(10, 78, 162, 0.16);

  --global-border-radius: 0.25rem;
}

[data-theme='default'] {
  --primary-background-color: #fff;
  --primary-hover-background-color: #0d6efd61;

  --table-odd-row-background-color: #e9ebf0;

  --primary-text-color: rgba(0, 0, 0, 0.87);
  --disabled-text-color: rgba(0, 0, 0, 0.38);

  --secondary-background-color: #dadada;

  --scrollbar-thumb-color: #515152;
  --scrollbar-track-color: #fff;

  --sidebar-background-color: #fbfcff;
  --sidebar-item-color: #2598eb;
  --sidebar-item-active-color: #0d6efd;

  --dispatch-odd-color: #ffffff;
  --dispatch-even-color: #e9e9e9;
}

[data-theme='dark'] {
  --primary-background-color: #212529;
  --primary-hover-background-color: #0e5597;

  --table-odd-row-background-color: #2f3337;

  --primary-text-color: #fff;
  --disabled-text-color: rgba(255, 255, 255, 0.38);

  --secondary-background-color: #363636;

  --scrollbar-thumb-color: #fff;
  --scrollbar-track-color: #515152;

  --sidebar-background-color: #2a2a2e;
  --sidebar-item-color: #1e1e21;
  --sidebar-item-active-color: #0d6efd;

  --dispatch-odd-color: #2e2e2e;
  --dispatch-even-color: #4a4a4a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mulish', sans-serif;
}

html,
body {
  width: 100vw;
  height: 100vh;
  /*font-size: 1.6vmin;*/
  overflow-x: hidden;
}

body {
  background: var(--primary-background-color) !important;
  color: var(--primary-text-color) !important;
}

#app {
  height: 100%;
  overflow: auto;
}

.app {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding-left: 3.2em;
}

::-webkit-scrollbar {
  width: 0.32rem;
  height: 0.32rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

.theme-toggle {
  position: fixed;
  bottom: 1em;
  right: 1em;
  width: 2.4em;
  height: 2.4em;
  background: var(--sidebar-item-color);
  color: #fff;
  line-height: 2.4em;
  text-align: center;
  border-radius: 0.5em;
  transition: all 0.3s;
  cursor: pointer;
}

.theme-toggle:hover {
  background: var(--sidebar-item-active-color);
}

.page {
  height: 100%;
  overflow: auto;
}

input[type='color'] {
  --webkit-appearance: none;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
}

.ag-theme-material {
  --ag-header-background-color: var(--primary-background-color);
  --ag-header-foreground-color: var(--primary-text-color);
  --ag-header-cell-hover-background-color: var(--primary-hover-background-color);

  --ag-subheader-background-color: var(--primary-background-color, var(--ag-subheader-background-color));
  --ag-foreground-color: var(--primary-text-color);
  --ag-secondary-foreground-color: var(--primary-text-color);

  --ag-background-color: var(--primary-background-color);
  --ag-odd-row-background-color: var(--table-odd-row-background-color); /* */
  --ag-row-hover-color: var(--primary-hover-background-color);
  --ag-data-color: var(--primary-text-color);
  --ag-disabled-foreground-color: var(--disabled-text-color);
}

.ag-header-icon {
  color: var(--primary-text-color);
}

.modal {
  display: none;
  background: rgba(0, 0, 0, 0.6);
}

.modal.show {
  display: block;
}

.modal .modal-content {
  background: var(--primary-background-color);
  color: var(--primary-text-color);
}

.modal .btn-close {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='red'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  color: var(--primary-text-color);
}

.accordion {
  background-color: var(--secondary-background-color);
}

.accordion-button.collapsed {
  color: var(--primary-text-color);
  background-color: var(--secondary-background-color);
}

#disconnected {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--primary-background-color);
  color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  user-select: none;
}
</style>
