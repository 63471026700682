import { accountFragment, vehicleTypeFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {import('fifty-seven-llc-common').VehicleTypeStructure} data
 */
export const createVehicleType = data =>
  makeRequest({
    operationName: 'CreateVehicleType',
    query: `
      mutation CreateVehicleType($data: VehicleTypeCreateInput!) {
        createVehicleType(data: $data) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { data },
  });

/**
 * @param {string} id
 * @param {Partial<import('fifty-seven-llc-common').VehicleTypeStructure>} data
 */
export const updateVehicleType = (id, data) =>
  makeRequest({
    operationName: 'UpdateVehicleType',
    query: `
      mutation UpdateVehicleType($id: String!, $data: VehicleTypeUpdateInput!) {
        updateVehicleType(id: $id, data: $data) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id, data },
  });

/**
 * @param {string} id
 */
export const deleteVehicleType = id =>
  makeRequest({
    operationName: 'DeleteVehicleType',
    query: `
      mutation DeleteVehicleType($id: String!) {
        deleteVehicleType(id: $id) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

/**
 * @param {string} id
 */
export const restoreVehicleType = id =>
  makeRequest({
    operationName: 'RestoreVehicleType',
    query: `
      mutation RestoreVehicleType($id: String!) {
        restoreVehicleType(id: $id) {
          ...VehicleTypeFragment
        }
      }
      
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
