import { accountFragment, locationFragment, personFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {import('fifty-seven-llc-common').PersonStructure} data
 */
export const createPerson = data =>
  makeRequest({
    operationName: 'CreatePerson',
    query: `
      mutation CreatePerson($data: PersonCreateInput!) {
        createPerson(data: $data) {
          ...PersonFragment
        }
      }
      
      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { data },
  });

/**
 * @param {string} id
 * @param {Partial<import('fifty-seven-llc-common').PersonStructure>} data
 */
export const updatePerson = (id, data) =>
  makeRequest({
    operationName: 'UpdatePerson',
    query: `
      mutation UpdatePerson($id: String!, $data: PersonUpdateInput!) {
        updatePerson(id: $id, data: $data) {
          ...PersonFragment
        }
      }
      
      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { id, data },
  });

/**
 * @param {string} id
 */
export const deletePerson = id =>
  makeRequest({
    operationName: 'DeletePerson',
    query: `
      mutation DeletePerson($id: String!) {
        deletePerson(id: $id) {
          ...PersonFragment
        }
      }
      
      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

/**
 * @param {string} id
 */
export const restorePerson = id =>
  makeRequest({
    operationName: 'RestorePerson',
    query: `
      mutation RestorePerson($id: String!) {
        restorePerson(id: $id) {
          ...PersonFragment
        }
      }
      
      ${locationFragment}
      ${personFragment}
      ${accountFragment}
    `,
    variables: { id },
  });
