import { locationFragment } from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {number?} take
 * @param {number?} skip
 * @param {string?} search
 */
export const getLocationList = (take = 30, skip = 0, search) =>
  makeRequest({
    operationName: 'LocationList',
    query: `
      query LocationList($take: Int, $skip: Int, $search: String) {
        locations(take: $take, skip: $skip, search: $search) {
          ...LocationFragment
        }
      }
      
      ${locationFragment}
    `,
    variables: { take, skip, search },
  });
