import {
  accountFragment,
  equipmentFragment,
  locationFragment,
  personFragment,
  vehicleContactFragment,
  vehicleFragment,
  vehicleTypeFragment,
  vehicleEquipmentFragment,
  vehicleReservationFragment,
} from '../fragments';
import { makeRequest } from '../utils';

/**
 * @param {import('fifty-seven-llc-common').VehicleStructure} data
 */
export const createVehicle = data =>
  makeRequest({
    operationName: 'CreateVehicle',
    query: `
      mutation CreateVehicle($data: VehicleCreateInput!) {
        createVehicle(data: $data) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { data },
  });

/**
 * @param {string} id
 * @param {Partial<import('fifty-seven-llc-common').VehicleStructure>} data
 */
export const updateVehicle = (id, data) =>
  makeRequest({
    operationName: 'UpdateVehicle',
    query: `
      mutation UpdateVehicle($id: String!, $data: VehicleUpdateInput!) {
        updateVehicle(id: $id, data: $data) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id, data },
  });

/**
 * @param {string} id
 */
export const deleteVehicle = id =>
  makeRequest({
    operationName: 'DeleteVehicle',
    query: `
      mutation DeleteVehicle($id: String!) {
        deleteVehicle(id: $id) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

export const restoreVehicle = id =>
  makeRequest({
    operationName: 'RestoreVehicle',
    query: `
      mutation RestoreVehicle($id: String!) {
        restoreVehicle(id: $id) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { id },
  });

export const reserveVehicle = (vehicleId, duration, accountId) =>
  makeRequest({
    operationName: 'ReserveVehicle',
    query: `
      mutation ReserveVehicle($vehicleId: String!, $accountId: String, $duration: Int!) {
        reserveVehicle(vehicleId: $vehicleId, accountId: $accountId, duration: $duration) {
          ...VehicleReservationFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
    variables: { vehicleId, accountId, duration },
  });

export const releaseVehicle = vehicleId =>
  makeRequest({
    operationName: 'ReleaseVehicle',
    query: `
      mutation ReleaseVehicle($vehicleId: String!) {
        releaseVehicle(vehicleId: $vehicleId) {
          ...VehicleReservationFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
      ${vehicleReservationFragment}
    `,
    variables: { vehicleId },
  });

export const bookVehicle = (vehicleId, locationId, onRouteTill) =>
  makeRequest({
    operationName: 'BookVehicle',
    query: `
      mutation BookVehicle($vehicleId: String!, $locationId: String!, $onRouteTill: DateTime!) {
        bookVehicle(vehicleId: $vehicleId, locationId: $locationId, onRouteTill: $onRouteTill) {
          ...VehicleFragment
        }
      }
      
      ${vehicleFragment}
      ${vehicleContactFragment}
      ${vehicleEquipmentFragment}
      ${personFragment}
      ${equipmentFragment}
      ${locationFragment}
      ${vehicleTypeFragment}
      ${accountFragment}
    `,
    variables: { vehicleId, locationId, onRouteTill },
  });
